<script setup lang="ts">
import { MinisterialBannerProps } from "./MinisterialBanner.props"
import { WrapperImageProps } from "~/components/Utils/WrapperImage.props"
import { getImageData } from "~/utils/wrapperImage"

defineProps<MinisterialBannerProps>()

const modalId = ref("ministerialBanner" + Math.random)
const { openModal, closeModal } = useModal()

const bgImage: WrapperImageProps = getImageData({
  altText: "background image",
  url: "/i5oh5hzamkwo/6JKljp4pblUBEmoZcscPwH/719238ac6a3ae885c88d6ee58fe58c6f/ministerial-banner.svg",
  width: 1500,
  height: 500
})
</script>

<template>
  <div
    class="
      relative
      overflow-hidden
      bg-green-main
      p-4
      pb-8
      md:flex
      md:gap-x-10
      md:rounded-lg
      md:p-0
      md:pt-8
    "
  >
    <UtilsWrapperImage
      v-if="bgImage"
      v-bind="bgImage"
      class="wrapper-image-full-container-centered absolute inset-0 z-0"
    />

    <div
      v-if="gallery.length && gallery[0]"
      class="
        gallery-image
        relative
        z-10
        mb-5
        md:order-1
        md:mb-0
        md:overflow-hidden
        md:rounded-lg
      "
    >
      <UtilsWrapperImage
        v-bind="gallery[0]"
        class="
          wrapper-image-fixed-188 wrapper-image-full-container-centered
          md:wrapper-image-fixed-236
        "
      />
    </div>

    <div class="relative z-10 text-white md:pb-6 md:pl-10">
      <p class="elephant-bold mb-4">{{ title }}</p>

      <p class="beaver mb-8">{{ description }}</p>

      <UtilsButton
        class="w-full text-black-main md:w-auto"
        :text="$t('simpleTranslations.discoverMore')"
        order="last"
        @click="openModal(modalId)"
      >
        <template #icon>
          <UtilsIcon name="ArrowRight.svg" class="h-5 w-5" />
        </template>
      </UtilsButton>
    </div>

    <DialogsAndModalsUtilsModal :id="modalId">
      <DialogsAndModalsStandard
        :title="modalTitle"
        @on-close="closeModal(modalId)"
      >
        <template #content>
          <div class="h-[80vh] w-[80vw] overflow-y-scroll px-4 py-6 md:px-8">
            <UtilsWrapperImage
              v-for="image in gallery"
              v-bind="image"
              class="wrapper-image-full"
            />
          </div>
        </template>
      </DialogsAndModalsStandard>
    </DialogsAndModalsUtilsModal>
  </div>
</template>
